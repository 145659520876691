import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import SolProviderContext from "../../SolProviderContext";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import Loading from "../../Loading";
import BN from "bn.js";
import {getSolScanTxUrl} from "../../contract/util";

const MySwal = withReactContent(Swal)

const Button = styled.button`
  width: 336px;
  height: 90px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 360px;
  }
`;

const MINIMUM_AMOUNT = new BN(1000).mul(new BN(10).pow(new BN(9)));

function StakeButton({months, amount, disabled}: any) {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  const [isLoading, setIsLoading] = useState(false);

  const stake = async (months: number, amount: BN) => {
    if (!solProvider) {
      await MySwal.fire({
        text: "지갑을 연결해주세요.",
        icon: 'warning',
      });
      return undefined;
    }

    if (amount.cmp(MINIMUM_AMOUNT) < 0) {
      await MySwal.fire({
        html: `
        oops! something went wrong. </br>
        The minimum staking amount is 1,000paz.
        `,
        icon: 'error',
      });
      return undefined;
    }

    setIsLoading(true);
    try {
      const result = await stakeClient?.stake(months, solAddress, amount);
      // const result = await client?.send();
      setIsLoading(false);
      const url = getSolScanTxUrl(result);
      await MySwal.fire({
        html: `
        <a href="${url}" target="_blank" rel="noopener noreferrer">Explorer</a>
        `,
        icon: 'success',
      });
      console.log(result);
    } catch (err) {
      setIsLoading(false);
      let msg = err;
      if (err != undefined && (err as any).code == "-32603") {
        msg = "Something went wrong within Phantom.";
      }
      await MySwal.fire({
        text: JSON.stringify(msg),
        icon: 'error',
      });
      console.log(`staking error ${JSON.stringify(err)}`);
    }
  }

  return (
    <div>
      {isLoading && <Loading/>} {}
      <Button disabled={disabled} onClick={() => stake(months, amount)}>
        {"STAKING"}
      </Button>
    </div>
  );
}

export default StakeButton