import styled from 'styled-components';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import Swal from 'sweetalert2'
import {PublicKey} from '@solana/web3.js';
import phantom from './web/navbar/phantom.svg';
import withReactContent from 'sweetalert2-react-content'
import SolProviderContext from "./SolProviderContext";
import {StakeClient} from "./contract/stake";
import {NODE_URL} from "./config";

const MySwal = withReactContent(Swal)

const ConnectButton = styled.button`
  height: 52px;
  width: 204px;
  margin-right: 120px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
`;

const ConnectedButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 204px;
  height: 52px;
  margin-right: 120px;
  flex-shrink: 0;
  background: #000;
  border: none;
  cursor: pointer;
`;

const WalletImage = styled.img`
  width: 26px;
  height: 21px;
  margin-left: 39px;
  flex-shrink: 0;
`;

const WalletAddress = styled.div`
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

`;

function ConnectWalletButton({onProviderChange}: any) {
  const context = useContext(SolProviderContext);
  const { solProvider, setSolProvider, setSolAddress, solAddress, setStakeClient }: any = context;
  const [connected, setConnected] = useState(false); // 연결 상태를 관리하는 state
  const [simpleSolAddress, setSimpleSolAddress] = useState("");

  useEffect(() => {
    setSimpleSolAddress(getSimpleSolAddress(solAddress));
  }, [solAddress]);

  const connectToPhantom = useCallback(async () => {
    try {
      // 팬텀 설치 된 경우
      if (typeof window.solana !== 'undefined' && window.solana.isPhantom) {
        const response = await window.solana.connect({onlyIfTrusted: false});
        const _solAddress = response.publicKey.toBase58();
        setSolAddress(_solAddress)
        setSolProvider(window.phantom.solana)
        setStakeClient(new StakeClient(NODE_URL, window.phantom.solana));
        window.solana.on('accountChanged', async (publicKey: PublicKey | null) => {
          if (publicKey) {
            const _solAddress = publicKey.toBase58();
            setSolAddress(_solAddress)
            setSolProvider(window.phantom.solana)
            setStakeClient(new StakeClient(NODE_URL, window.phantom.solana));
          } else {
            await MySwal.fire({
              text: "팬텀 지갑 연결에 실패했습니다. 다시 시도해주세요.",
              icon: 'error',
            });
          }
        })

        setConnected(true);
      } else {
        await MySwal.fire({
          titleText: '지갑 연결 오류',
          html: `
    oops! something went wrong.<br />
    Please check your Phantom wallet.<br />
    Install link: <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer">https://phantom.app/</a>
  `,
          icon: 'error',
        });
      }
    } catch (error: any) {
      console.log(error)
      await MySwal.fire({
        titleText: '에러가 발생했습니다.',
        text: error.toString(),
        icon: 'error',
      });
    }
  }, [])

  const handleConnected = async () => {
      setConnected(false);
      setSolProvider(undefined);
      setSolAddress(undefined);
      setStakeClient(undefined);
      setSimpleSolAddress("");
  };

  return (
    connected
      ? <ConnectedButton onClick={handleConnected}>
        <WalletImage src={phantom}></WalletImage>
        <WalletAddress>{simpleSolAddress}</WalletAddress>
      </ConnectedButton>
      : <ConnectButton onClick={connectToPhantom}> Connect Wallet </ConnectButton>
  );
}

function getSimpleSolAddress(solAddress: string): string {
  if (!solAddress) {
    return "no";
  }
  const start = solAddress.substring(0, 4); // 앞 4자리
  const end = solAddress.substring(solAddress.length - 4); // 뒤 4자리
  return `${start}...${end}`;
}

export default ConnectWalletButton