import React from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  background-color: #000;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
  text-align: center;
  padding-top: 20px; 
  padding-left: 28px; 
`;

const LinkContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px; 
`;

const Terms = styled.a`
  height: 23px;
  flex-shrink: 0;

  color: #FFF;
  font-family: Poppins;
  font-size: 12.695px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
  text-decoration: none; 
  &:hover {
    color: #999;
  }
`

const Vector = styled.img`
  width: 2px;
  height: 14px;
  flex-shrink: 0;

  stroke-width: 1.5px;
  stroke: #FFF;
  margin-left: 10px;
  margin-right: 21px;
`;

const PrivacyPolicy = styled.a`
  height: 23px;
  flex-shrink: 0;

  color: #FFF;
  font-family: Poppins;
  font-size: 12.695px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-decoration: none; 
  &:hover {
    color: #999;
  }
`;

const Company = styled.div`
  width: 335px;
  height: 23px;
  flex-shrink: 0;

  left: 120px;
  bottom: 52px;
  text-align: left;

  color: #8C8C8C;
  font-family: Poppins;
  font-size: 11.108px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SnsLinks = styled.div`
  
  justify-content: left;
  display: flex;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 32px; 
  padding-left: 28px; 
`;

const SnsLink = styled.a`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

function Footer() {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <SnsLinks>
        <SnsLink href="https://t.me/PAZ_ann" target="_blank" rel="noreferrer">
          <img src={"/images/telegram.svg"} alt="Telegram"/>
        </SnsLink>
        <SnsLink href="https://discord.com/invite/pararium" target="_blank" rel="noreferrer">
          <img src={"/images/discord.svg"} alt="Discord"/>
        </SnsLink>
        <SnsLink href="https://twitter.com/pararium_world" target="_blank" rel="noreferrer">
          <img src={"/images/tweet.svg"} alt="Twitter"/>
        </SnsLink>
      </SnsLinks>
      <Description>
        <LinkContainer>
          <Terms onClick={() => navigate('/terms')}
          >Terms</Terms>
          <Vector src={"/images/vector.svg"}></Vector>
          <PrivacyPolicy onClick={() => navigate('/terms')}
          >PrivacyPolicy</PrivacyPolicy>
        </LinkContainer>
        <Company>© Minerva Ceres LLC All right reserved</Company>
      </Description>
    </FooterContainer>
  );
}

export default Footer;
