// StakingContext.tsx

import { createContext, useContext, ReactNode, useState } from 'react';
import {StakedItem, UnstakedItem} from "./types";

interface StakingContextType {
  stakedItems: StakedItem[];
  setStakedItems: React.Dispatch<React.SetStateAction<StakedItem[]>>;
  unstakedItems: UnstakedItem[];
  setUnstakedItems: React.Dispatch<React.SetStateAction<UnstakedItem[]>>;
}

const StakingContext = createContext<StakingContextType | undefined>(undefined);

export const StakingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [stakedItems, setStakedItems] = useState<StakedItem[]>([]);
  const [unstakedItems, setUnstakedItems] = useState<UnstakedItem[]>([]);

  return (
    <StakingContext.Provider value={{ stakedItems, setStakedItems, unstakedItems, setUnstakedItems }}>
      {children}
    </StakingContext.Provider>
  );
};

export const useStaking = (): StakingContextType => {
  const context = useContext(StakingContext);
  if (!context) {
    throw new Error("useStaking must be used within a StakingProvider");
  }
  return context;
};
