// type environment = "dev" | "main";
// const env: environment = "dev";

export let STAKE_MINT_ADDRESS: any, STAKE_ADDRESS: any, NODE_URL: any, RIUM_ADMIN_ADDRESS: any, API_URL: any, WON_PER_RIUM: number, PARARIUM_APP_URL: any;


console.log(process.env.REACT_APP_STAGE)
var env = process.env.REACT_APP_STAGE


// @ts-ignore
if (env == "dev") {
  STAKE_MINT_ADDRESS = "T1XFL1V49GguzFVKvSjkfSke6Z7EehFH8m9Z1TbLCXg";
  STAKE_ADDRESS = "C96RB3xyVLBbY8nDUF1NZGAY9eXxrDNQZfrpfRULndKf";
  RIUM_ADMIN_ADDRESS = "2JiD1i6XtJibnEKH7To12XJwBWax5SBbufWYFXar6w71"
  NODE_URL = "https://devnet.helius-rpc.com/?api-key=40dc1dab-fa1c-4c53-bdc2-cea03ca776e1"
  API_URL = "http://localhost:3000"
  WON_PER_RIUM = 1000;
  PARARIUM_APP_URL = 'https://neko.api.ap-northeast-2.dev.pararium.net/v1'

} else if (env == "prod") {
  STAKE_MINT_ADDRESS = "9BYsCifqrsN1AL1BpXaQv6HWgNZcgYZ5Hgqos7xfd1ji";
  STAKE_ADDRESS = "C96RB3xyVLBbY8nDUF1NZGAY9eXxrDNQZfrpfRULndKf";
  RIUM_ADMIN_ADDRESS = "Ftu5qDXYmnjiNkDuzerCbZ4pbuXmfYHtT7kjJ1udUikP"
  NODE_URL = "https://responsive-purple-dinghy.solana-mainnet.discover.quiknode.pro/e37b142796674e042de2c3ac6ae3bf8f50257214/"
  API_URL = "https://api.pararium.xyz"
  WON_PER_RIUM = 1000;
  PARARIUM_APP_URL = 'https://neko.api.ap-northeast-2.prod.pararium.net/v1'
}

