import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px; 
  margin: 0 auto;    
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60%;
  width: 100%;
  margin-top: 150px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 120px;
`;

export const MeaningOf = styled.div`
  width: 504px;
  color: #000;
  align-items: flex-start;
  text-align: left;
  font-family: Poppins;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: -10px;
`;

export const Paz = styled.div`
  display: flex;
  align-items: center;
  width: 504px;
  color: #E785FF;
  text-align: left;
  font-family: Poppins;
  font-size: 80px;
  font-weight: 600;
`;

export const PazImage = styled.img`
  width: 52px;
  height: 52px;
  margin-left: 9px;
  flex-shrink: 0;
`;

export const Description = styled.p`
  width: 583px;
  height: 104px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 380px;
  height: 380px;
  flex-shrink: 0;
  margin-right: 120px;

  img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
  }
`;

export const Arrow = styled.button<any>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.left ? "left: -64px;" : "right: -64px;")}
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const HorizontalSection = styled.img`
  width: 234px;
  height: 4px;
  flex-shrink: 0;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
`;

export const SPLText = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 107%;
  margin-left: 120px;
`;

export const SPLImage = styled.img`
  width: 67px;
  height: 67px;
  margin-left: 20px; 
  flex-shrink: 0;
`;

export const TokenText = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 107%;
  margin-top: 20px; 
  margin-left: 120px;
`;

export const RightTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 220px; 
`;

export const RightTopText = styled.div`
  width: 271px;
  height: 57px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.5%;
  white-space: pre-line; 
`;

export const RightBottomText = styled.div`
  width: 607px;
  height: 114px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 23px; 
`;