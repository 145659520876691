import warningBlack from "../staking/warning_black.svg";
import swapChange from "./swap_change.svg";
import React, {useContext, useEffect, useState} from "react";
import {
  BottomContent,
  Box, BoxesContainer,
  BoxTitle,
  BoxValue, ChangeButton, Container,
  Description,
  Info, LeftContentWrapper,
  RightContentWrapper, TabButton,
  Title, TotalRiumText, TotalTab
} from "./style";
import {WarningContainer, WarningText, WarningTextContainer} from "../staking/style";
import SolProviderContext from "../../SolProviderContext";
import {RiumClient} from "../../riumClient";
import BN from "bn.js";
import {bnToStringWithDecimals, stringNumberToBnWithDecimals} from "../../contract/util";
import BigNumber from "bignumber.js";
import SwapButton from "./SwapButton";
import {WON_PER_RIUM} from "../../config";
import {StakeClient} from "../../contract/stake";

BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN})

function SwapPage() {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  // let [sc, setSc] = useState<StakeClient>();
  const [riumPoint, setRiumPoint] = useState(0);
  const [pazAmount, setPazAmount] = useState("0");
  const [tokenPrice, setTokenPrice] = useState(0);
  const [inputAmount, setInputAmount] = useState("0.0");
  const [calculatedInput, setCalculatedInput] = useState("0.0");
  const [calculatedOutput, setCalculatedOutput] = useState("0.0");
  const [outputAmount, setOutputAmount] = useState("0");
  const [riumToPaz, setRiumToPaz] = useState(false);
  const riumClient: RiumClient = new RiumClient();

  const init = async () => {
    const rp = await riumClient.getRium(solAddress);
    setRiumPoint(rp);
    const tp = await riumClient.getTokenPrice();
    setTokenPrice(tp);
    const pp = await stakeClient!.getPazAmount(solAddress);
    console.log(`pp ${pp!.toString()}`);
    setPazAmount(pp!.toString())
    console.log(`rp ${rp}`);
    console.log(`tp ${tp}`);
  }

  const onSwapComplete = () => {
    if (solProvider) {
      init();
    }
  };

  useEffect(() => {
    if (solProvider) {
      init();
      // sc = new StakeClient(
      //   'https://devnet.helius-rpc.com/?api-key=40dc1dab-fa1c-4c53-bdc2-cea03ca776e1',
      //   solProvider,
      //   'C96RB3xyVLBbY8nDUF1NZGAY9eXxrDNQZfrpfRULndKf',
      //   'T1XFL1V49GguzFVKvSjkfSke6Z7EehFH8m9Z1TbLCXg'
      // );
      // setSc(sc);
    } else {
      setRiumPoint(0);
      setTokenPrice(0);
      setPazAmount("0");
    }
  }, [solProvider]);

  useEffect(() => {
    const fromPlaceHolder = riumToPaz
      ? `0.0`
      : '0'
    setInputAmount(fromPlaceHolder);
    setCalculatedInput(fromPlaceHolder);
    setCalculatedOutput("0.0");
  }, [riumToPaz]);

  // TODO: API Call

  const calculateRiumToPaz = (inputValue: string) => {
    return; // TODO
    if (!solProvider) {
      return;
    }
    // 1.2에서 1. 도 통과
    if (/^\d+(\.\d{0,9})?$/.test(inputValue) || inputValue === "") {
      setCalculatedInput(inputValue);

      // 1.2에서 1.2만 통과
      if (/^\d+(\.\d{0,9})?$/.test(inputValue)) {
        if (!inputValue) {
          return;
        }
        if (riumPoint < Number(inputValue)) {
          inputValue = String(riumPoint);
        }
        setCalculatedInput(inputValue);
        setInputAmount(inputValue);
        const paz = new BigNumber(inputValue)
          .mul(WON_PER_RIUM)
          .div(tokenPrice)
          .mul(new BigNumber(10).pow(9))
          .floor();
        setCalculatedOutput(paz.div(new BigNumber(10).pow(9)).toString());
        setOutputAmount(paz.toString());
      }
    }
  }

  const calculatePazToRium = (inputValue: string) => {
    if (!solProvider) {
      return;
    }
    if (!inputValue) {
      setCalculatedInput("");
      setOutputAmount("0");
      setCalculatedOutput("0.0");
    }
    // 정수형만 통과
    if (/^\d+$/.test(inputValue)) {
        if (!inputValue) {
          return;
        }
        let parsedInputValue = new BigNumber(inputValue).mul(new BigNumber(10).pow(9)).toString();
        if (new BN(pazAmount).cmp(new BN(parsedInputValue)) < 0) {
          inputValue = new BigNumber(pazAmount).div(new BigNumber(10).pow(9)).toFixed(0);
          parsedInputValue = pazAmount;
        }
        setCalculatedInput(inputValue);
        setInputAmount(parsedInputValue)
        let rium = new BigNumber(inputValue)
          .mul(tokenPrice)
          .mul(95)
          .div(WON_PER_RIUM).toString();
        const rium2 = new BigNumber(Math.floor(parseFloat(rium.toString()) * 10000000000) / 10000000000).toString();
        const rium3 = new BigNumber(rium2)
          .div(100)
          .toFixed(10);
        setCalculatedOutput(rium3);
        setOutputAmount(rium3);
      }
  }

  const calculateOutput = riumToPaz
    ? calculateRiumToPaz
    : calculatePazToRium;

  const description = `$PAZ and Rium can be exchanged at a rate of 1 Rium for every N amount of $PAZ.\nEnter the quantity of Rium point and check the minimum amount paid.`

  const infos = riumToPaz
    ? [`You can quickly exchange based on the liquidity pool. `,
      `The minimum payment amount may vary depending on the state of the liquidity pool.`]
    : [`You can quickly exchange.`,
      `The minimum payment amount may vary depending on the state of the liquidity pool.`,
      `5% swap fee will be charged.`];

  const fromAsset = riumToPaz
    ? `(P) Rium`
    : `PAZ`

  const toAsset = riumToPaz
    ? `PAZ`
    : `(P) Rium`

  // Paz -> Rium Swap 시 Paz는 정수만 가능
  const fromPlaceHolder = riumToPaz
    ? `0.0`
    : '0'

  const warningText = riumToPaz
    ? `Can swap 50 Rium per day`
    : `Minimum point is 5 Rium`

  const toggleSwapDirection = () => {
    return; // TODO
    setRiumToPaz(!riumToPaz);
  };

  const maxButtonHandler = () => {
    if (riumToPaz) {
      const input = riumPoint.toString();
      setCalculatedInput(input);
      setInputAmount(input);
      calculateRiumToPaz(input);
    } else {
      const calculatedInput = new BN(pazAmount).div(new BN(10).pow(new BN(9))).toString();
      setCalculatedInput(calculatedInput);
      setInputAmount(pazAmount);
      calculatePazToRium(calculatedInput);
    }
  }

  const halfButtonHandler = () => {
    if (riumToPaz) {
      const calculatedInput = (riumPoint/2).toString();
      setCalculatedInput(calculatedInput);
      setInputAmount(calculatedInput);
      calculateRiumToPaz(calculatedInput);
    } else {
      const calculatedInput = new BN(pazAmount).div(new BN(2)).div(new BN(10).pow(new BN(9))).toString();
      setCalculatedInput(calculatedInput);
      setInputAmount(new BN(pazAmount).div(new BN(2)).toString());
      calculatePazToRium(calculatedInput);
    }
  }

  return (
    <Container>
      <LeftContentWrapper>
        <Title>SWAP</Title>
        <Description>{description}</Description>
        {infos.map((info, index) => <Info key={index}>{info}</Info>)}
      </LeftContentWrapper>
      <RightContentWrapper>
        <BoxesContainer>
          <TotalTab>
            <TotalRiumText>Total Rium: {riumPoint.toLocaleString()}</TotalRiumText>
            <div>
              <TabButton onClick={maxButtonHandler}>Max</TabButton>
              <TabButton onClick={halfButtonHandler}>Half</TabButton>
            </div>
          </TotalTab>
          <Box style={{marginBottom: 10}}>
            <BoxTitle>From</BoxTitle>
            <BottomContent>
              <BoxTitle style={{fontWeight: 'bold'}}>{fromAsset}</BoxTitle>
              <input
                style={{
                  textAlign: 'right',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: '14px',
                  border: 'none',
                  outline: 'none',
                  marginRight: 34,
                  color: (inputAmount === "0.0" || inputAmount === "0") ? 'rgba(0, 0, 0, 0.20)' : '#000',
                }}
                placeholder={fromPlaceHolder}
                value={calculatedInput}
                onFocus={() => {
                  if (calculatedInput === "0.0" || calculatedInput === "0") {
                    setCalculatedInput("");
                  }
                }}
                onChange={(e) => {
                  let val = e.target.value;
                  calculateOutput(val);
                }}
              />
            </BottomContent>
          </Box>
          <ChangeButton image={swapChange} onClick={toggleSwapDirection}/>
          <Box style={{marginTop: 10}}>
            <BoxTitle>To</BoxTitle>
            <BottomContent>
              <BoxTitle style={{
                fontWeight: 700,
                marginTop: "auto",
                marginBottom: "16px"
              }}>{toAsset}</BoxTitle>
              <BoxValue style={{
                marginRight: 34,
                fontWeight: 700
              }}>{calculatedOutput}</BoxValue>
            </BottomContent>
          </Box>
          <WarningContainer>
            <WarningTextContainer image={warningBlack}>
              <WarningText color="#FFF" style={{fontWeight: 700, fontSize: 14}}>i</WarningText>
            </WarningTextContainer>
            <WarningText color="#000">{warningText}</WarningText>
          </WarningContainer>
          <SwapButton
            inputAmount={inputAmount}
            outputAmount={outputAmount}
            riumToPaz={riumToPaz}
            disabled={outputAmount == "0"}
            onSwapComplete={onSwapComplete}>
            SWAP
          </SwapButton>
        </BoxesContainer>
      </RightContentWrapper>
    </Container>
  );
}

export default SwapPage;