import styled from "styled-components";
import warningBlack from "./warning_black.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 768px) {
    margin-top: 50px;
    max-width: 360px;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  font-weight: 600;
  margin: 10px;
  @media (max-width: 768px) {
    color: #000;
    font-family: Poppins;
    text-align: left;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 0px;
  }
`;

export const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0 auto;
  @media (max-width: 768px) {
    text-align: start;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 508px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const Box = styled.div`
  height: 90px;
  flex-shrink: 0;
  border: 1.5px solid #000;
  background: #FFF;
`;

export const BoxTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-left: 28px;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BoxValue = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  color: ${(props) => props.color || "#000"};
`;

export const WarningContainer = styled.div<{ right?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.right ? 'auto' : '0')};
`;

export const WarningTextContainer = styled.div<{ image?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.image || warningBlack});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 10px;
`;

export const WarningText = styled.p<{ color?: string }>`
  color: ${(props) => props.color || "#FFF"};
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const BackButton = styled.button`
  width: 159px;
  height: 90px;
  flex-shrink: 0;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BackButtonIcon = styled.img`
  width: 32px;
  height: 21.274px;
  flex-shrink: 0;
  margin-right: 11px;
  cursor: pointer;
`;

export const BackButtonText = styled.span`
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  border: none;
`;
