import styled from "styled-components";
import React from "react";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  height: 100%;
  padding: 16px;
  margin-bottom: 70px;
  flex-direction: column; // 항목들을 수직으로 정렬
  align-items: center; // 모든 항목들을 중앙 정렬
`;

const Title = styled.p`
  width: 100%;
  height: 80px;

  max-width: 360px; // StakingItems와 동일한 최대 너비를 갖도록 설정
  color: #000;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: left; 
  margin-top: 80px;
  margin-bottom: 3px;
`;

const Description = styled.p`
  width: 100%;
  flex-shrink: 0;
  max-width: 360px; // StakingItems와 동일한 최대 너비를 갖도록 설정

  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0 auto; // 중앙 정렬
  text-align: left; // 텍스트를 좌측 정렬
`;

const StakingItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 0 auto; // 중앙 정렬
  max-width: 360px; // 최대 너비 설정
`

const StakingItem = styled.div`
  width: 100%; 
  height: 220px;
  flex-shrink: 0;
  background: #FFF;
`;

const StakingDescriptionBox = styled.div`
  width: 100%;
  max-width: 358px;
  height: 150px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  background: #FFF;
  border: 1.5px solid #000;
  margin-top: 30px;
  margin-bottom: 5px; 
`

const StakingDescription = styled.p`
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  flex-shrink: 0;
  
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const StakingAPRDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const APRText = styled.span`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
`;

const APRValue = styled.span`
  color: var(--Style, #E785FF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;


const StakingButton = styled.button`
  width: 100%; 
  height: 70px;
  flex-shrink: 0;

  background: linear-gradient(0deg, #000 0%, #000 100%), #FFF;

  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  padding: 0px;
`;

function StakingPage() {
  const navigate = useNavigate();

  const handleStakingClick = (months: number) => {
    navigate('/staking-in', { state: { months } });
  };

  return (
    <Container>
      <Title>STAKING</Title>
      <Description>Staking products guaranteed by $PAZ allow you to deposit assets and earn stable
        returns. </Description>
      <Description> Gain rewards in a variety of ways through a variety of products.</Description>

      <StakingItems>
        <StakingItem>
          <StakingDescriptionBox>
            <StakingDescription>
              <strong>90 days</strong> deposit
              <br/>
              Minimum quantity of 1,000 paz
            </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>4.5% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(3)}>{"STAKING"}</StakingButton>
        </StakingItem>

        <StakingItem>
          <StakingDescriptionBox>
            <StakingDescription>
              <strong>180 days</strong> deposit
              <br/>
              Minimum quantity of 1,000 paz
            </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>8.48% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(6)}>{"STAKING"}</StakingButton>
        </StakingItem>

        <StakingItem>
          <StakingDescriptionBox>
            <StakingDescription>
              <strong>360 days</strong> deposit
              <br/>
              Minimum quantity of 1,000 paz
            </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>11.24% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(12)}>{"STAKING"}</StakingButton>
        </StakingItem>
      </StakingItems>
    </Container>
  )
}

export default StakingPage