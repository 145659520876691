import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Logo from "./Logo";
import sidebarHorizontalSection from "./sidebar_horizontal_section.svg";
import ConnectWalletButton from "../../ConnectWalletButton";

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 78px;
  z-index: 1000;
`;

const BurgerIcon = styled.div`
  width: 30px;
  height: 23.523px;
  margin: 26px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 24px;
    height: 3px;
    background-color: #000;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-image: url(${sidebarHorizontalSection});
`;

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center; 
  justify-content: flex-start; 
  font-size: 16px;
  color: #000;
  text-decoration: none;
  width: 100%;
  height: 60px;
  padding-left: 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Sidebar = styled.div<{ open: boolean }>`
  position: fixed;
  top: 78px; 
  left: 0;
  width: 250px;
  height: calc(100% - 78px);
  background: #FFF;
  box-shadow: 8px 0 15px -5px rgba(0, 0, 0, 0.1); // 오른쪽 그림자 추가
  padding: 10px;
  border: none;
  transform: translateX(${props => (props.open ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
`;

function Navbar() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSidebarOpen(false);
  };

  return (
    <NavContainer>
      <Logo onClick={() => navigate("/about")}></Logo>
      <BurgerIcon onClick={handleSidebarToggle}>
        <div />
        <div />
        <div />
      </BurgerIcon>
      <Sidebar open={sidebarOpen}>
          <ConnectWalletButton onClick={handleLinkClick}/>
        <div style={{marginBottom: '36px'}}></div>
        <Divider />
        <SidebarLink to="/about" onClick={handleLinkClick}>About PAZ</SidebarLink>
        <Divider />
        <SidebarLink to="/swap" onClick={handleLinkClick}>SWAP</SidebarLink>
        <Divider />
        <SidebarLink to="/staking" onClick={handleLinkClick}>STAKING</SidebarLink>
        <Divider />
        <SidebarLink to="/dashboard" onClick={handleLinkClick}>DASHBOARD</SidebarLink>
        <Divider />
        <SidebarLink to="/my-page" onClick={handleLinkClick}>MY PAGE</SidebarLink>
        {sidebarOpen && <div onClick={handleSidebarClose} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />}
      </Sidebar>
      {sidebarOpen && <div onClick={handleSidebarClose} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1}} />}
    </NavContainer>
  );
}

export default Navbar;
