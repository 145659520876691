import React from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  width: 100%;
  height: 180px;
  flex-shrink: 0;
  margin-top: 50px;
`;

const Description = styled.div`
  width: 208px;
  height: 25px;
  margin-left: 120px;
  margin-top: 65px;
  margin-bottom: 90px;
`;

const Terms = styled.a`
  width: 60px;
  height: 23px;
  flex-shrink: 0;

  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
  text-decoration: none; // 텍스트 밑줄 제거
  &:hover {
    color: #999;
  }
`

const Vector = styled.img`
  width: 2px;
  height: 14px;
  flex-shrink: 0;
  
  margin-left: 16px;
  margin-right: 21px;

  stroke-width: 1.5px;
  stroke: #FFF;
`;

const PrivacyPolicy = styled.a`
  width: 121px;
  height: 23px;
  flex-shrink: 0;

  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-decoration: none; // 텍스트 밑줄 제거
  &:hover {
    color: #999;
  }
`;

const Company = styled.div`
  width: 335px;
  height: 23px;
  flex-shrink: 0;

  left: 120px;
  bottom: 52px;
  margin-top: 17px;

  color: #8C8C8C;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SnsLinks = styled.div`
    display: flex;
    gap: 30px;
    margin-right: 120px;
`;

const SnsLink = styled.a`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

function Footer() {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <Description>
        <Terms onClick={() => navigate('/terms')}
        >Terms</Terms>
        <Vector src={"/images/vector.svg"}></Vector>
        <PrivacyPolicy onClick={() => navigate('/terms')}
        >PrivacyPolicy</PrivacyPolicy>
        <Company>© Minerva Ceres LLC All right reserved</Company>
      </Description>
      <SnsLinks>
        <SnsLink href="https://t.me/PAZ_ann" target="_blank" rel="noreferrer">
          <img src={"/images/telegram.svg"} alt="Telegram" />
        </SnsLink>
        <SnsLink href="https://discord.com/invite/pararium" target="_blank" rel="noreferrer">
          <img src={"/images/discord.svg"} alt="Discord" />
        </SnsLink>
        <SnsLink href="https://twitter.com/pararium_world" target="_blank" rel="noreferrer">
          <img src={"/images/tweet.svg"} alt="Twitter" />
        </SnsLink>
      </SnsLinks>
    </FooterContainer>
  );
}

export default Footer;
