import React from 'react';
import styled from 'styled-components';
import ConnectWalletButton from "../../ConnectWalletButton";
import Logo from "./Logo";
import {useNavigate} from "react-router-dom";

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 52px;
  z-index: 1000;
`;

const NavItems = styled.div`
    display: flex;
    gap: 70px;
    left: 693px;
    right: 50px;
`;

const NavItem = styled.a`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const WalletConnect = styled.div`
`;

function Navbar() {
  const navigate = useNavigate();

  return (
    <NavContainer>
      <Logo onClick={() => navigate("/about")}></Logo>
      <NavItems>
        <NavItem onClick={() => navigate("/about")}>About PAZ</NavItem>
        <NavItem onClick={() => navigate("/swap")}>SWAP</NavItem>
        <NavItem onClick={() => navigate("/staking")}>STAKING</NavItem>
        <NavItem onClick={() => navigate("/dashboard")}>DASHBOARD</NavItem>
        <NavItem onClick={() => navigate("/my-page")}>MY PAGE</NavItem>
      </NavItems>
      <WalletConnect>
        {<ConnectWalletButton/>}
      </WalletConnect>
    </NavContainer>
  );
}

export default Navbar;
