import React, {createContext, useState} from "react";

const SolProviderContext = createContext<{
  solProvider: any;
  setSolProvider: React.Dispatch<React.SetStateAction<any>>;
} | undefined>(undefined);

export const SolProvider = ({ children }: any) => {
  const [solProvider, setSolProvider] = useState();
  const [solAddress, setSolAddress] = useState();
  const [stakeClient, setStakeClient] = useState();

  return (
    <SolProviderContext.Provider value={{ solProvider, setSolProvider, solAddress, setSolAddress, stakeClient, setStakeClient } as any}>
      {children}
    </SolProviderContext.Provider>
  );
};


export default SolProviderContext