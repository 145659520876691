import styled from "styled-components";

const mediaQueries = {
  mobile: `(max-width: 768px)`,
};

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column; // 모바일에서는 column 방향으로 변경
  align-items: center; // 모바일에서는 센터 정렬
  width: 100%;
  padding: 20px; // 모바일에서 패딩 추가
  margin-top: 150px;
  margin-left: 12px;

  @media ${mediaQueries.mobile} {
    margin-top: 50px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MeaningOf = styled.div`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Paz = styled.div`
  color: #E785FF;
  text-align: center;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PazImage = styled.img`
  width: 34.74px;
  height: 34.74px;
  flex-shrink: 0;
`;

export const Description = styled.p`
  height: 104px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-top: 78px;

  @media ${mediaQueries.mobile} {
    width: 100%; // 모바일에서는 너비를 100%로 설정
    height: auto; 
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Arrow = styled.button<any>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")} // 모바일에서 화살표 위치 조절

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media ${mediaQueries.mobile} {
    top: 45%; // 모바일에서 화살표 위치 상단 조절
  }
`;

export const HorizontalSection = styled.img`
  width: 100%;
  height: 4px;
  flex-shrink: 0;
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  margin-left: 12px;
`;

export const SPLText = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 107%;
`;

export const SPLImage = styled.img`
  width: 67px;
  height: 67px;
  margin-left: 20px;
  flex-shrink: 0;
`;

export const TokenText = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 107%;
  margin-top: 20px;
`;

export const BottomTextContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
`;

export const BottomTextTitle = styled.div`
  height: 57px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.5%;
  white-space: pre-line;
`;

export const BottomText = styled.div`
  height: 114px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 23px;
`;