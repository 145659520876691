import axios from 'axios';
import {API_URL} from "./config";
import {StakeClient} from "./contract/stake";

interface SwapRiumToPazRequestDto {
  riumPoint: string;
  address: string,
  base64Tx: string,
  pazAmount: string;
}

interface SwapPazToRiumRequestDto {
  pazAmount: string;
  address: string,
  base64Tx: string,
  riumPoint: string,
}

export class RiumClient {
  private readonly url: string;

  constructor() {
    this.url = API_URL;
  }

  async getTokenPrice(): Promise<number> {
    const { data } =  await axios.get(`${this.url}/token-price`);
    return data;
  }

  async getRium(address: string): Promise<number> {
    const { data } = await axios.get(`${this.url}/users/${address}/rium`);
    return data;
  }

  async swapRiumToPaz(riumPoint: string, pazAmount: string, address: string, base64Tx: string): Promise<string> {
    const requestDto: SwapRiumToPazRequestDto = {
      riumPoint,
      address,
      base64Tx,
      pazAmount
    }
    const { data } = await axios.post(
      `${this.url}/users/swap-rium-to-paz`,
      requestDto,
    );
    return data.transactionHash;
  }

  async swapPazToRium(pazAmount: string, riumPoint: string, address: string, base64Tx: string): Promise<string> {
    const requestDto: SwapPazToRiumRequestDto = {
      pazAmount,
      address,
      base64Tx,
      riumPoint
    }
    const { data } = await axios.post(
      `${this.url}/users/swap-paz-to-rium`,
      requestDto,
    );
    return data.transactionHash;
  }
}