import React, {useContext, useEffect, useState} from 'react';
import {
  Container, InfoContainer, TabContainer, Title, InfoBox,
  InfoImage, InfoText1, InfoText2, ActiveButton, InactiveButton, Header,
  Tab, Divider, ContentContainer, Content, RowContainer, ContentWrapper
} from './style';
import SolProviderContext from "../../SolProviderContext";
import {useStaking} from "../../StakingContext";
import {useInView} from "react-intersection-observer";
import {StakeClient, StakingItem} from "../../contract/stake";
import {
  bnToStringWithDecimals,
  calculateReward,
  convertToDateFormat,
  getSolScanAccountUrl,
  getUnstakingDate
} from "../../contract/util";
import {StakedItem, UnstakedItem} from "../../types";
import UnstakeButton from "../../web/staking/UnstakeButton";
import {RiumClient} from "../../riumClient";

function MyPage() {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  const [activeTab, setActiveTab] = useState(1); // 1이면 1번 탭, 2이면 2번 탭
  const {stakedItems, setStakedItems, unstakedItems, setUnstakedItems} = useStaking();
  const [stakedAmount, setStakedAmount] = useState("0");
  const [riumPoint, setRiumPoint] = useState(0);
  const riumClient: RiumClient = new RiumClient();
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const initStakingItems = async () => {
    const rp = await riumClient.getRium(solAddress);
    setRiumPoint(rp);
    const hasStaking = await (stakeClient as StakeClient).hasStaking(solAddress);
    if (!hasStaking) {
      setStakedItems([]);
      setUnstakedItems([]);
      return;
    }

    const stakingCount = await (stakeClient as StakeClient).getAllStakingCounts(solAddress);
    if (stakingCount > stakedItems.length) {
      const stakingItems: StakingItem[] = await (stakeClient as StakeClient).getAllStakings(solAddress);
      setStakedItems(
        stakingItems.map((item): StakedItem => {
          const stakedAtDate = new Date(item.stakedAt * 1000);
          const now = new Date(Date.now());
          const days = item.months * 30;
          const unstakingDate = getUnstakingDate(stakedAtDate, days)
          return {
            id: item.id,
            explorer: getSolScanAccountUrl(item.pda),
            stakedDate: convertToDateFormat(stakedAtDate),
            endDate: convertToDateFormat(unstakingDate),
            quantity: bnToStringWithDecimals(item.quantity),
            interest: bnToStringWithDecimals(calculateReward(item.quantity, item.months)),
            months: item.months,
            canUnstake: now >= unstakingDate && item.isFirst,
          }
        })
      );

      const amountSum = stakingItems
        .map(s => s.quantity)
        .reduce((a, b) => a.add(b))

      setStakedAmount(bnToStringWithDecimals(amountSum))
    }

    const unstakingCount = await (stakeClient as StakeClient).getAllUnstakingCounts(solAddress);
    if (unstakingCount > unstakedItems.length) {
      const unstakingItems: StakingItem[] = await (stakeClient as StakeClient).getAllUnstakings(solAddress);
      setUnstakedItems(
        unstakingItems.map((item): UnstakedItem => {
          const stakedAtDate = new Date(item.stakedAt * 1000);
          const now = new Date(Date.now());
          const days = item.months * 30;
          const unstakingDate = getUnstakingDate(stakedAtDate, days)
          return {
            id: item.id,
            explorer: getSolScanAccountUrl(item.pda),
            stakedDate: convertToDateFormat(stakedAtDate),
            endDate: convertToDateFormat(unstakingDate),
            quantity: bnToStringWithDecimals(item.quantity),
            interest: bnToStringWithDecimals(calculateReward(item.quantity, item.months)),
            months: item.months,
            canUnstake: now >= unstakingDate && item.isFirst,
            days: days,
          }
        })
      );
    }
  }

  useEffect(() => {
    if (!solProvider) {
      setStakedItems([]);
      setUnstakedItems([]);
      setStakedAmount("0");
      setRiumPoint(0);
      return;
    }

    initStakingItems();
  }, [solProvider])

  useEffect(() => {
    if (inView) {
      fetchMoreData();
    }
  }, [inView]);

  const fetchMoreData = async () => {
    // TODO
  };

  return (
    <Container>
      <Title>MY PAGE</Title>
      <InfoContainer>
        <InfoBox>
          <InfoImage src={"/images/paz_token_black.svg"}/>
          <InfoText1>Staked Amount</InfoText1>
          <InfoText2>{stakedAmount}</InfoText2>
        </InfoBox>
        <InfoBox>
          <InfoImage src={"/images/rium.svg"}/>
          <InfoText1>Rium</InfoText1>
          <InfoText2>{riumPoint}</InfoText2>
        </InfoBox>
      </InfoContainer>
      <TabContainer>
        <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Current</Tab>
        <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>History</Tab>
      </TabContainer>
      {activeTab === 1 ?
        (
          stakedItems?.map((item, index) => (
            <ContentContainer>
              <RowContainer>
                <Header style={{fontSize: 16}}>EXPLORER</Header>
                <ContentWrapper>
                  <Content style={{fontSize: 16, color: "#1037FF"}}>
                    <a style={{textDecoration: "none"}} href={item.explorer} target="_blank" rel="noopener noreferrer">
                      In Solscan
                    </a>
                  </Content>
                </ContentWrapper>
              </RowContainer>
              <Divider src="/images/my-page-vector-mobile.svg" alt="divider"/>
              <RowContainer>
                <Header>STAKED DATE</Header>
                <ContentWrapper>
                  <Content>{item.stakedDate}</Content>
                </ContentWrapper>
              </RowContainer>
              <RowContainer>
                <Header>EXPECTED END DATE</Header>
                <ContentWrapper>
                  <Content>{item.endDate}</Content>
                </ContentWrapper>
              </RowContainer>
              <RowContainer>
                <Header>STAKED QUANTITY</Header>
                <ContentWrapper>
                  <Content>{item.quantity}</Content>
                </ContentWrapper>
              </RowContainer>
              <RowContainer>
                <Header>INTEREST</Header>
                <ContentWrapper>
                  <Content>{item.interest}</Content>
                </ContentWrapper>
              </RowContainer>
              <UnstakeButton months={item.months} stakingId={item.id} canStake={item.canUnstake}></UnstakeButton>
            </ContentContainer>
          ))
        ) : (
          unstakedItems?.map((item, index) => (
              <ContentContainer>
                <RowContainer>
                  <Header style={{fontSize: 16}}>EXPLORER</Header>
                  <ContentWrapper>
                    <Content style={{fontSize: 16, color: "#1037FF"}}>
                      <a style={{textDecoration: "none"}} href={item.explorer} target="_blank" rel="noopener noreferrer">
                        In Solscan
                      </a>
                    </Content>
                  </ContentWrapper>
                </RowContainer>
                <Divider src="/images/my-page-vector-mobile.svg" alt="divider"/>
                <RowContainer>
                  <Header>STAKED DATE</Header>
                  <ContentWrapper>
                    <Content>{item.stakedDate}</Content>
                  </ContentWrapper>
                </RowContainer>
                <RowContainer>
                  <Header>RESULT</Header>
                  <ContentWrapper>
                    <Content>SUCCESS</Content>
                  </ContentWrapper>
                </RowContainer>
                <RowContainer>
                  <Header>STAKED QUANTITY</Header>
                  <ContentWrapper>
                    <Content>{item.quantity}</Content>
                  </ContentWrapper>
                </RowContainer>
                <RowContainer>
                  <Header>INTEREST</Header>
                  <ContentWrapper>
                    <Content>{item.interest}</Content>
                  </ContentWrapper>
                </RowContainer>
                <RowContainer>
                  <Header>PRODUCT</Header>
                  <ContentWrapper>
                    <Content>{item.days}days</Content>
                  </ContentWrapper>
                </RowContainer>
              </ContentContainer>
            )
          )
        )}
    </Container>
  );
}

export default MyPage;