import React, {useState} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  overflow: auto; // 스크롤 속성 추가
  max-width: 1440px;
  margin: 0 auto;
`;

const Title = styled.p`
  width: 100%;
  height: 80px;

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 35px;
`;

const Description = styled.p`
  width: 821px;
  flex-shrink: 0;

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0 auto;
`;

const StakingItems = styled.div`
  display: flex;
  gap: 52px;
  
  margin-top: 50px;
  margin-bottom: 110px;
`

const StakingItem = styled.div`
  width: 281px;
  height: 318px;
  flex-shrink: 0;

  border: 1.5px solid #000;
  background: #FFF;
`;

const StakingDescriptionBox = styled.div`
  width: 281px;
  height: 318px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: #FFF;
`

const StakingDescription = styled.p`
  width: 205px;
  height: 86px;
  margin-top: 72px;
  margin-bottom: 47px;
  flex-shrink: 0;

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const StakingAPRDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const APRText = styled.span`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
`;

const APRValue = styled.span`
  color: var(--Style, #E785FF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;


const StakingButton = styled.button`
  width: 282px;
  height: 77px;
  flex-shrink: 0;
  
  margin-top: 12px;
  background: linear-gradient(0deg, #000 0%, #000 100%), #FFF;

  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
`;

function StakingPage() {
  const navigate = useNavigate();

  const handleStakingClick = (months: number) => {
    navigate('/staking-in', { state: { months } });
  };

  return (
    <Container>
      <Title>STAKING</Title>
      <Description>Staking products guaranteed by $PAZ allow you to deposit assets and earn stable
        returns. </Description>
      <Description> Gain rewards in a variety of ways through a variety of products.</Description>

      <StakingItems>
        <StakingItem>
          <StakingDescriptionBox>
          <StakingDescription>
            <strong>90 days</strong> deposit
            <br/>
            Minimum quantity of
            <br/>
            1,000 paz
          </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>4.5% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(3)}>{"STAKING"}</StakingButton>
        </StakingItem>

        <StakingItem>
          <StakingDescriptionBox>
            <StakingDescription>
                <strong>180 days</strong> deposit
                <br/>
                Minimum quantity of
                <br/>
                1,000 paz
            </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>8.48% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(6)}>{"STAKING"}</StakingButton>
        </StakingItem>

        <StakingItem>
          <StakingDescriptionBox>
              <StakingDescription>
                <strong>360 days</strong> deposit
                <br/>
                Minimum quantity of
                <br/>
                1,000 paz
            </StakingDescription>
            <StakingAPRDescription>
              <APRText>Maximum Annual Return</APRText>
              <APRValue>11.24% (APR)</APRValue>
            </StakingAPRDescription>
          </StakingDescriptionBox>
          <StakingButton onClick={() => handleStakingClick(12)}>{"STAKING"}</StakingButton>
        </StakingItem>
      </StakingItems>
    </Container>
  );
}

export default StakingPage;
