import React from 'react';
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 117px;
  height: 30px;
  margin-left: 20px;
  flex-shrink: 0;
`;

const LogoImage = styled.img`
  width: 29px;
  height: 29px;
  flex-shrink: 0;
  margin-right: 10px;
`;

const LogoName = styled.div`
  color: #000;
  font-family: Poppins;
  width: 78px;
  height: 30px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
`;

function Logo({ onClick }: any) {
  return (
    <LogoContainer onClick={onClick}>
      <LogoImage
      src={"/images/logo.svg"}></LogoImage>
      <LogoName>
        <p>Staking</p>
      </LogoName>
    </LogoContainer>

  )
}

export default Logo