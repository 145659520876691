import {useLocation} from "react-router-dom";
import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {PublicKey} from "@solana/web3.js";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { useParams } from 'react-router-dom';
import {PARARIUM_APP_URL} from "../../config";
const MySwal = withReactContent(Swal)
const bs58 = require('bs58')

export function Verification() {
  const [solAddress, setSolAddress] = useState("");
  const [solProvider, setSolProvider] = useState(undefined);
  const [connected, setConnected] = useState(false);
  const { token } = useParams();
  const _token = Buffer.from(bs58.decode(token ?? "")).toString();
  useEffect(() => {
    const init = async () => {
     await connectToPhantom();
    }
    init();
    if (solProvider) {
      setConnected(true);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      if (solProvider && connected && _token) {
        try {
          const message = await requestMessage(_token, solAddress);
          const signature = await sign(message);
          const sig = bs58.encode(signature.signature);
          await verify(_token, message, sig);

          await MySwal.fire({
            titleText: '지갑 연동이 완료 되었습니다.',
            text: "The wallet linkage has been completed.\r\nYou can check it on the Pararium app.",
            icon: 'success',
          });
          
        } catch (error: any) {
          const msg = (error && error.response && error.response.data) ? error.response.data : error;
          await MySwal.fire({
            titleText: '에러가 발생했습니다.',
            text: typeof msg == "string" ? msg : msg.toString(),
            icon: 'error',
          });
        }
      }
    }

    init();
  }, [connected]);

  const sign = async (message: string) => {
    const encodedMessage = new TextEncoder().encode(message);
    const signedMessage = await (solProvider as any).signMessage(encodedMessage, 'utf8');
    return signedMessage;
  }

  const connectToPhantom = async () => {

    try {
      // 팬텀 설치 된 경우
      if (typeof window.solana !== 'undefined' && window.solana.isPhantom) {
        const response = await window.solana.connect({onlyIfTrusted: false});
        const _solAddress = response.publicKey.toBase58();
        console.log(`solAddress ${_solAddress}`);
        setSolAddress(_solAddress)
        setSolProvider(window.phantom.solana)
        window.solana.on('accountChanged', async (publicKey: PublicKey | null) => {
          if (publicKey) {
            const _solAddress = publicKey.toBase58();
            setSolAddress(_solAddress)
            setSolProvider(window.phantom.solana)
          } else {
            await MySwal.fire({
              text: "팬텀 지갑 연결에 실패했습니다. 다시 시도해주세요.",
              icon: 'error',
            });
          }
        })

        setConnected(true);
      } else {
        await MySwal.fire({
          titleText: '지갑 연결 오류',
          html: `
    oops! something went wrong.<br />
    Please check your Phantom wallet.<br />
    Install link: <a href="https://phantom.app/" target="_blank" rel="noopener noreferrer">https://phantom.app/</a>
  `,
          icon: 'error',
        });
      }
    } catch (error: any) {
      console.log(error)
      await MySwal.fire({
        titleText: '에러가 발생했습니다.',
        text: error.toString(),
        icon: 'error',
      });
    }
  }

  return <div></div>;
}


async function requestMessage(token: string, address: string): Promise<string> {
  const {data} = await axios.post(`${PARARIUM_APP_URL}/challenge/solana/request`,
    {
      address : address,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  );

  return data;
}

async function verify(token: string, message: string, signature: string) {
  const {data} = await axios.post(
    `${PARARIUM_APP_URL}/challenge/solana/verify`,
    {
      message: message,
      signature: signature,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  );

  return data;
}