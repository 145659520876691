import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import back from './back.svg';
import warningBlack from './warning_black.svg';
import SolProviderContext from "../../SolProviderContext";
import StakeButton from "./StakeButton";
import BN from "bn.js";
import {
  BackButton,
  BackButtonIcon, BackButtonText,
  BottomContent,
  Box,
  BoxTitle,
  BoxValue, ButtonContainer,
  Container,
  ContentWrapper,
  Description,
  Title,
  WarningContainer, WarningText, WarningTextContainer
} from "./style";
import {STAKE_MINT_ADDRESS} from "../../config";
import {
  bnToStringWithDecimals,
  calculateReward,
  getNextMonthAndDaysDateRange,
  stringNumberToBnWithDecimals
} from "../../contract/util";

function StakingInPage() {
  const {solAddress, stakeClient}: any = useContext(SolProviderContext);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("0.0");
  const [amount, setAmount] = useState<BN>(new BN(0));
  const [calculatedValue, setCalculatedValue] = useState("0.0");
  const [balance, setBalance] = useState<BN>(new BN(0));
  const [dateText, setDateText] = useState("");
  const location = useLocation();
  const {months = 3} = location.state || {};

  useEffect(() => {
    const a = stringNumberToBnWithDecimals(inputValue);
    console.log(`amount ${a.toString()}`);
    setAmount(a)
  }, [inputValue]);

  useEffect(() => {
    const fetchBalance = async (mintAddress: string, solAddress: string) => {
      try {
        let b = await stakeClient.getTokenBalance(solAddress)
        if (b) {
          console.log(`token balance: ${b.toString()}`);
          setBalance(b);
        }
      } catch (error) {
        console.error('Failed to fetch balance', error);
      }
    };

    if (months && solAddress) {
      fetchBalance(STAKE_MINT_ADDRESS, solAddress);
    }

    const [fromDate, toDate] = getNextMonthAndDaysDateRange(months * 30);

    setDateText(`${fromDate} ~ ${toDate}`);
  }, [months, solAddress]);

  const handleBackClick = () => {
    navigate('/staking');
  };

  return (
    <Container>
      <Title>STAKING</Title>
      <Description>Staking products guaranteed by $PAZ allow you to deposit assets and earn stable
        returns.</Description>
      <Description>Gain rewards in a variety of ways through a variety of products.</Description>
      <div style={{margin: 18}}></div>
      <ContentWrapper>
        <Box>
          <BoxTitle>STAKING Quantity</BoxTitle>
          <BottomContent>
            <BoxTitle style={{fontWeight: 'bold'}}>$PAZ</BoxTitle>
            <input
              style={{
                textAlign: 'right',
                fontFamily: 'Poppins',
                fontWeight: 700,
                fontSize: '14px',
                border: 'none',
                outline: 'none',
                marginRight: 34,
                color: inputValue !== "0.0" ? '#000' : 'rgba(0, 0, 0, 0.20)',
                width: '100%',
              }}
              placeholder="0.0"
              value={inputValue}
              onFocus={() => {
                if (inputValue === "0.0") {
                  setInputValue("");
                }
              }}
              onChange={(e) => {
                let val = e.target.value;
                // 1.2에서 1. 도 통과
                if (/^\d+(\.\d{0,9})?$/.test(val) || val === "") {
                  setInputValue(val);

                  // 1.2에서 1.2만 통과
                  if (/^\d+(\.\d{1,9})?$/.test(val)) {
                    let bnVal = stringNumberToBnWithDecimals(val);
                    if (balance && balance.cmp(bnVal) < 0) {
                      bnVal = balance;
                      setInputValue(bnToStringWithDecimals(balance));
                    }
                    const expected = calculateReward(bnVal, months);
                    setCalculatedValue(bnToStringWithDecimals(expected));
                  }
                }
              }}
            />
          </BottomContent>
        </Box>
        <div style={{margin: 22}}></div>
        {/*balance가 얼만지 보여주는 view가 없기 때문에 balance를 넘어서는 quantity를 입력 시 balance를 입력하게 함.*/}
        {/*<WarningContainer right>*/}
        {/*  <WarningTextContainer image={warningPink}>*/}
        {/*    <WarningText color="var(--Style, #E785FF)" style={{fontWeight: 700, fontSize: 14}}>i</WarningText>*/}
        {/*  </WarningTextContainer>*/}
        {/*  <WarningText color="var(--Style, #E785FF)">Exceeds the quantity held</WarningText>*/}
        {/*</WarningContainer>*/}
        <Box>
          <BoxTitle>{dateText} <strong>({months * 30} days)</strong></BoxTitle>
          <BottomContent>
            <BoxTitle style={{fontWeight: 700, marginTop: "auto", marginBottom: "16px"}}>Expected Received
              Value</BoxTitle>
            <BoxValue style={{
              color: "var(--Style, #E785FF)",
              marginRight: 34,
              fontWeight: 700
            }}>{calculatedValue}</BoxValue>
          </BottomContent>
        </Box>
        <WarningContainer>
          <WarningTextContainer image={warningBlack}>
            <WarningText color="#FFF" style={{fontWeight: 700, fontSize: 14}}>i</WarningText>
          </WarningTextContainer>
          <WarningText color="#000">Staking takes place at the end of each month.</WarningText>
        </WarningContainer>
        <ButtonContainer>
          <BackButton onClick={handleBackClick}>
            <BackButtonIcon src={back} alt="Back"/>
            <BackButtonText>BACK</BackButtonText>
          </BackButton>
          <StakeButton months={months} amount={amount} disabled={amount.isZero()}>
            STAKING
          </StakeButton>
        </ButtonContainer>
      </ContentWrapper>
    </Container>
  );
}

export default StakingInPage;

