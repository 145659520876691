import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import SolProviderContext from "../../SolProviderContext";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import {StakeClient} from "../../contract/stake";
import {NODE_URL} from "../../config";
import Loading from "../../Loading";
import BN from "bn.js";
import {getSolScanTxUrl} from "../../contract/util";

const MySwal = withReactContent(Swal)

export const ActiveButton = styled.button`
  width: 204px;
  height: 52px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px; 
  font-weight: 500;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 350px;
    padding: 0px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const InactiveButton = styled(ActiveButton)`
  opacity: 0.1;
  background: #000;
  color: #FFF;
`;

function UnstakeButton({months, stakingId, canStake}: any) {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  const [isLoading, setIsLoading] = useState(false);

  const unstake = async (months: number, stakingId: number) => {
    if (!solProvider) {
      await MySwal.fire({
        text: "지갑을 연결해주세요.",
        icon: 'warning',
      });
      return undefined;
    }

    setIsLoading(true);
    try {
      const result = await stakeClient?.unstake(months, new BN(stakingId), solAddress);
      // const result = await client?.send();
      setIsLoading(false);
      const url = getSolScanTxUrl(result);
      await MySwal.fire({
        html: `
        <a href="${url}" target="_blank" rel="noopener noreferrer">Explorer</a>
        `,
        icon: 'success',
      });
      console.log(result);
    } catch (err) {
      setIsLoading(false);
      await MySwal.fire({
        text: err as any,
        icon: 'error',
      });
      console.log(`staking error ${err}`);
    }
  }

  return (
    <div>
      {isLoading && <Loading/>} {}
      {canStake ?
      <ActiveButton onClick={() => unstake(months, stakingId)}>
        UNSTAKE
      </ActiveButton> :
        <InactiveButton>
          UNSTAKE
        </InactiveButton>
      }
    </div>
  );
}

export default UnstakeButton;