import React, {useContext, useEffect, useState} from "react";
import axios from 'axios';
import SolProviderContext from "../../SolProviderContext";
import BN from "bn.js";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {bnToStringWithDecimals, getSolScanTxUrl} from "../../contract/util";
import Loading from "../../Loading";
import styled from "styled-components";
import {RiumClient} from "../../riumClient";
import {StakeClient} from "../../contract/stake";
import {NODE_URL, STAKE_ADDRESS, STAKE_MINT_ADDRESS} from "../../config";
import BigNumber from "bignumber.js";
const MySwal = withReactContent(Swal)

const Button = styled.button`
  width: 100%;
  height: 90px;
  flex-shrink: 0;
  background: #000;
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 360px;
    margin-top: 20px;
  }
`;

const MINIMUM_RIUM_AMOUNT_WHEN_RIUM_TO_PAZ = 1;
const MINIMUM_RIUM_AMOUNT_WHEN_PAZ_TO_RIUM = 5;

function SwapButton({inputAmount, outputAmount, riumToPaz, disabled, onSwapComplete}: any) {
  const context = useContext(SolProviderContext);
  const {solProvider, solAddress, stakeClient}: any = context;
  // const [sc, setSc] = useState<StakeClient>();
  const [isLoading, setIsLoading] = useState(false);
  const riumClient = new RiumClient();

  // useEffect(() => {
  //   if (solProvider) {
  //     setSc(new StakeClient(
  //       "https://devnet.helius-rpc.com/?api-key=40dc1dab-fa1c-4c53-bdc2-cea03ca776e1",
  //       solProvider,
  //       STAKE_ADDRESS,
  //       "T1XFL1V49GguzFVKvSjkfSke6Z7EehFH8m9Z1TbLCXg"
  //     ))
  //   }
  //
  // }, [solProvider])

  const swapRiumToPaz = async (riumAmount: string, pazAmount: string) => {
    if (!solProvider) {
      await MySwal.fire({
        text: "지갑을 연결해주세요.",
        icon: 'warning',
      });
      return undefined;
    }

    if (Number(riumAmount) < MINIMUM_RIUM_AMOUNT_WHEN_RIUM_TO_PAZ) {
      await MySwal.fire({
        html: `
        oops! something went wrong. </br>
        The minimum rium point is 1.
        `,
        icon: 'error',
      });
      return undefined;
    }

    setIsLoading(true);
    try {
      const base64Tx = await stakeClient?.halfSignToTarget(solAddress, new BN(pazAmount));
      const result = await riumClient.swapRiumToPaz(riumAmount, pazAmount, solAddress, base64Tx);
      console.log(result);
      // const result = await client?.send();
      setIsLoading(false);
      const url = getSolScanTxUrl(result);
      await MySwal.fire({
        html: `
        <a href="${url}" target="_blank" rel="noopener noreferrer">Explorer</a>
        `,
        icon: 'success',
      });
      onSwapComplete();
      console.log(result);
    } catch (err) {
      setIsLoading(false);
      let msg = err;
      if (err != undefined && (err as any).code == "-32603") {
        msg = "Something went wrong within Phantom.";
      }

      if (axios.isAxiosError(err)) {
        msg = err.response?.data?.message;
        if (msg == 'Exceeded daily limit') {
          msg = 'Can swap 50Rium per day.';
        }
      }

      await MySwal.fire({
        text: JSON.stringify(msg),
        icon: 'error',
      });
      console.log(`error in swap rium to paz ${JSON.stringify(err)}, detailed message ${msg}`);
    }
  }

  const swapPazToRium = async (pazAmount: string, riumAmount: string) => {
    if (!solProvider) {
      await MySwal.fire({
        text: "지갑을 연결해주세요.",
        icon: 'warning',
      });
      return undefined;
    }

    console.log(riumAmount)
    if (Number(riumAmount) < MINIMUM_RIUM_AMOUNT_WHEN_PAZ_TO_RIUM) {
      await MySwal.fire({
        html: `
        oops! something went wrong. </br>
        The minimum rium point is 5.
        `,
        icon: 'error',
      });
      return undefined;
    }

    setIsLoading(true);
    try {
      const base64Tx = await stakeClient?.halfSignFromTarget(solAddress, new BN(pazAmount));
      const result = await riumClient.swapPazToRium(pazAmount, riumAmount, solAddress, base64Tx);
      console.log(result);
      // const result = await client?.send();
      setIsLoading(false);
      const url = getSolScanTxUrl(result);
      await MySwal.fire({
        html: `
        <a href="${url}" target="_blank" rel="noopener noreferrer">Explorer</a>
        `,
        icon: 'success',
      });
      onSwapComplete();
      console.log(result);
    } catch (err) {
      setIsLoading(false);
      let msg = err;
      if (err != undefined && (err as any).code == "-32603") {
        msg = "Something went wrong within Phantom.";
      }

      console.log(err);
      if (axios.isAxiosError(err)) {
        msg = err.response?.data?.message;
      }

      await MySwal.fire({
        text: JSON.stringify(msg),
        icon: 'error',
      });
      console.log(`error in swap paz to rium ${JSON.stringify(err)}, detailed message ${msg}`);
    }
  }

  return (
    <div>
      {isLoading && <Loading/>} {/* 로딩바 렌더링 */}
      <Button disabled={disabled} onClick={
        () => riumToPaz
          ? swapRiumToPaz(inputAmount, outputAmount)
          : swapPazToRium(inputAmount, outputAmount)
      }>
        {"SWAP"}
      </Button>
    </div>
  );
}

export default SwapButton