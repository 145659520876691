import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  font-family: Arial, sans-serif;
  background-color: #FFF;
  margin-top: 100px;
`;

const Title = styled.h1`
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  font-size: 1.5em;
  color: #444;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
    font-size: 1em;
    color: #555;
    line-height: 1.5;
    margin-bottom: 20px;
`;

const PrivacyAndTermPage: React.FC = () => {
  return (
    <PageContainer>
      <Title>Privacy Policy</Title>

      <Paragraph>
        Minerva Ceres Foundation (hereinafter referred to as PAZ) recognizes the importance of personal data privacy and user rights. Therefore, we have implemented a personal data protection policy aimed at providing security and transparency for our users when using the PAZ Swap website, Paz.swap (hereinafter referred to as "the Site"). The use and collection of data comply with the provisions of the UK Data Protection Act of 2017 and the Data Protection Regulation of 2018.
      </Paragraph>

      <Paragraph>
        Data processing will be carried out by the clauses stipulated below:
      </Paragraph>

      <SubTitle>Name and address of the Data Manager:</SubTitle>
      <Paragraph>
        Minerva Ceres Foundation, Suite 102, Cannon Place, P.O. Box 712, North Sound Rd., Grand Cayman, KY1-9006.
      </Paragraph>

      <SubTitle>Name and address of the Data Processing Manager:</SubTitle>
      <Paragraph>
        The entity in charge of data processing and protection of information gathered on the Site is Minerva Ceres Foundation.
      </Paragraph>

      <SubTitle>Processed data and management of personal data:</SubTitle>
      <Paragraph>
        The processing of personal data will solely be used to manage our platform and provide better service for our users. Personal data will be treated with the appropriate degree of protection, taking the necessary security measures to prevent loss, mistreatment, or unauthorized access by third parties.
      </Paragraph>

      <SubTitle>Legal basis for the processing of personal data:</SubTitle>
      <Paragraph>
        The legal basis for the treatment of data is the user's consent, and the processing is necessary to provide the services within the website.
      </Paragraph>

      <Paragraph>
        The management and processing of personal data will be carried out within the regulatory framework of The Ombudsman Law; The Complaints (Maladministration) Law; The Police (Complaints by the Public); The Whistleblower Protection Law; The Freedom of Information Law; The Data Protection Law, and Data Protection Regulation in compliance with the EU's General Data Protection Regulation (GDPR) standards.
      </Paragraph>

      <SubTitle>Information Paz.Swap Collects from You:</SubTitle>
      <Paragraph>
        We collect information about you directly from you, as well as automatically through your use of our Site. The limited information we collect is used to provide services to you. When you visit the Site, we only use the public key data of the users to provide services within the website. When you visit the Site, Google Analytics is used and may automatically collect the following information about your use of our Site through cookies and other technologies: your IP address; your browser type and operating system; links you click; and geolocation data; the amount of time you visit our Site; the referring URL or the web page that brought you to our Site. This information will be used solely for statistical purposes. By using the Site, you authorize us to collect, analyze, and retain data related to the provision of the Site.
      </Paragraph>

      <SubTitle>Information Collected by Third Parties:</SubTitle>
      <Paragraph>
        Phantom is an open-source, non-custodial key management network that enables users to create and log in to a Web3 wallet with an existing social account.
      </Paragraph>

      <Paragraph>
        If you decide to use Phantom services, you must allow Torus to use the following personal data:
      </Paragraph>

      <Paragraph>
        (1) Public data related to the account, which may include the profile photo, username, and email address. Private data related to the account, such as registered addresses and passwords, will not be collected by Torus and are not mandatory for the use of the service.
      </Paragraph>

      <Paragraph>
        Paz.swap will not process any of the data registered with Phantom wallet.
      </Paragraph>

      <SubTitle>7. Purpose of data processing:</SubTitle>
      <Paragraph>
        The user-provided data will be processed to complete registration forms and enable the use of the Site and its applications.
      </Paragraph>

      <SubTitle>8. Duration of storage and deletion of data:</SubTitle>
      <Paragraph>
        Personal data will be stored as long as necessary to provide the contracted services and will be kept stored for possible re-consultations by clients for the maximum time legally permitted by law.
      </Paragraph>

      <SubTitle>9. Communication and Data Transfer:</SubTitle>
      <Paragraph>
        The Data Processing Manager will collect data to provide services contracted by the user within the framework of this service. Personal data will not be communicated or transferred to third parties without the express consent of the user.
      </Paragraph>

      <SubTitle>10. Limited Right to Use:</SubTitle>
      <Paragraph>
        Paz.swap grants a limited right to use the Site. Use of the Site is subject to agreement to abide by these Terms and Conditions in their entirety, as well as any other rules, procedures, policies, terms, or conditions governing all or any portion of the Site. At any time and for any reason, Paz.swap may revoke the right to use all or any portion of the Site.
      </Paragraph>

      <SubTitle>11. Confidentiality:</SubTitle>
      <Paragraph>
        Personal data will be treated with the utmost reserve and confidentiality and will not be communicated, transferred, or disclosed to third parties not named in this policy.
      </Paragraph>

      <SubTitle>12. Exercise of Rights Regarding Data Processing and Withdrawal of Commercial Communications:</SubTitle>
      <Paragraph>
        Users may request access, rectification, cancellation, opposition, and withdrawal of commercial communications and personal information at any time in compliance with the EU's GDPR (General Data Protection Regulation) Right of Access, which has the following principles:
      </Paragraph>

      <Paragraph>
        a) Access: Users may access all the data being processed.<br/>
        b) Rectification: Users may request rectification of personal data they consider inaccurate.<br/>
        c) Cancellation: Users may request the total or partial deletion of their personal data if they no longer wish it to be processed. However, if the data is necessary for file and registration management, it will not be possible to continue providing the contracted service.<br/>
        d) Opposition: Users may oppose the processing of their data if they consider it pertinent. However, if the data is necessary for file and registration management, the service cannot be provided without it.<br/>
        e) Withdrawal of Commercial Communications: Users may request to stop receiving commercial communications, offers, and newsletters without affecting the contracted services.
      </Paragraph>

      <SubTitle>13. Procedure:</SubTitle>
      <Paragraph>
        Users may exercise their Rights of Access, rectification, opposition, cancellation, and withdrawal of personal information, commercial communications, offers, and newsletters by sending an email to CS@Paz.swap with the subject line: "Data Rights". Please include your name, and we will contact you as soon as possible.
      </Paragraph>
    </PageContainer>
  );
};

export default PrivacyAndTermPage;