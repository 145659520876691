import BN from "bn.js";

const THREE_MONTHS_ARP = 11250; // 4.5%
const SIX_MONTHS_ARP = 41900; // 8.48%
const TWELVE_MONTHS_ARP = 112400; // 11.24%
const RATE_PRECISION = 1000000

export function calculateReward(amount: BN, months: number) {
  let rate: BN;
  switch (months) {
    case 3:
      rate = new BN(THREE_MONTHS_ARP);
      break;
    case 6:
      rate = new BN(SIX_MONTHS_ARP);
      break;
    case 12:
      rate = new BN(TWELVE_MONTHS_ARP);
      break;
    default:
      rate = new BN(THREE_MONTHS_ARP);
      console.log(`${months} is unsupported months`);
  }
  return amount.mul(rate).div(new BN(RATE_PRECISION))
}

export function getUnstakingDate(from: Date, days: number): Date {
  const nextMonthFirstDate = new Date(from.getFullYear(), from.getMonth() + 1, 1); // 다음 달 1일
  return new Date(nextMonthFirstDate.getTime() + ((days - 1) * 24 * 60 * 60 * 1000)); // 다음 달 1일로부터 지정된 일수 후의 날짜, 시작 일 포함 (days - 1)
}

export function getNextDaysFromNextMonths(date: Date, days: number): string[] {
  return getNextMonthAndDaysDateRangeInternal(date, days);
}

export function getNextMonthAndDaysDateRange(days: number): string[] {
  const now = new Date(Date.now());
  return getNextMonthAndDaysDateRangeInternal(now, days);
}

export function convertToDateFormat(date: Date): string {
  const formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('ko-KR', formatOptions).replace(/\. /g, '.').replace(/\.$/, '');
}

function getNextMonthAndDaysDateRangeInternal(from: Date, days: number) {
  const nextMonthFirstDate = new Date(from.getFullYear(), from.getMonth() + 1, 1); // 다음 달 1일
  const fromAfterDaysDate = new Date(nextMonthFirstDate.getTime() + ((days - 1) * 24 * 60 * 60 * 1000)); // 다음 달 1일로부터 지정된 일수 후의 날짜, 시작 일 포함 (days - 1)

  const formattedNextMonthFirstDate = convertToDateFormat(nextMonthFirstDate);
  const formattedFromAfterDaysDate = convertToDateFormat(fromAfterDaysDate);

  return [formattedNextMonthFirstDate, formattedFromAfterDaysDate];
}

export function stringNumberToBnWithDecimals(value: string): BN {
  const parts = value.toString().split('.');
  const whole = parts[0];
  const decimal = parts[1] || "";
  const paddedDecimal = (decimal + "000000000").slice(0, 9);
  return new BN(whole + paddedDecimal);
}

export function bnToStringWithDecimals(bnValue: BN, decimalPlaces = 9) {
  let stringValue = bnValue.toString();

  while (stringValue.length <= decimalPlaces) {
    stringValue = '0' + stringValue;
  }

  const integerPart = stringValue.slice(0, -decimalPlaces);
  let fractionalPart = stringValue.slice(-decimalPlaces);

  let lastIndexNotZero = -1;
  for (let i = fractionalPart.length - 1; i >= 0; i--) {
    if (fractionalPart[i] !== '0') {
      lastIndexNotZero = i;
      break;
    }
  }

  if (lastIndexNotZero !== -1) { // 0이 아닌 숫자를 찾았다면
    fractionalPart = fractionalPart.slice(0, lastIndexNotZero + 1);
  } else { // 모든 숫자가 0이라면
    fractionalPart = "0";
  }

  return `${integerPart}.${fractionalPart}`;
}

export function getSolScanTxUrl(tx: string | undefined) {
  return `https://solscan.io/tx/${tx}`;
}

export function getSolScanAccountUrl(account: string | undefined) {
  return `https://solscan.io/account/${account}`;
}