import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
`;

const Images = styled.div`
  width: 217px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Image = styled.img`
  width: 67px;
  height: 67px;
  flex-shrink: 0;
`

const Text = styled.div`
  width: 226px;
  height: 60px;
  flex-shrink: 0;

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 26px;
`

function WorkInProcess() {
  return (
    <Container>
        <Images>
          <Image src={"/images/star.svg"}></Image>
          <Image src={"/images/ellipse.svg"}></Image>
          <Image src={"/images/polygon.svg"}></Image>
        </Images>
        <Text>OOPS! we're working on!</Text>
    </Container>
  )
}

export default WorkInProcess