import React, { useState } from "react";
import {
  Arrow, BottomSection,
  Container,
  Description, HorizontalSection,
  ImageContainer,
  MeaningOf,
  Paz,
  PazImage, RightBottomText, RightTextContainer, RightTopText, SPLImage, SPLText,
  TextContainer, TokenText,
  TopSection
} from "./style";

function AboutPage() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ["/images/announcement1.svg", "/images/announcement2.svg", "/images/announcement3.svg"];

  return (
    <Container>
      <TopSection>
        <TextContainer>
          <MeaningOf>MEANING OF</MeaningOf>
          <Paz>
            PAZ <PazImage src="./images/paz_token_pink.svg" alt="PAZ Icon" />
          </Paz>
          <Description>
            $PAZ is a digital asset that embodies two meanings, both of which are derived from the word 'Parts', which is a homonym of the word 'PAZ', and 'Paradium A to Z'. The term 'Parts' refers to the digital assets that are used in the Paradium ecosystem, while 'Paradium A to Z' encapsulates the infinite possibilities and diverse experiences that the Paradium network offers.
          </Description>
        </TextContainer>
        <ImageContainer>
          <img src={images[currentImageIndex]} alt="Right Top" />
          <Arrow left onClick={() => setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length)}>
            <img src="./images/arrow_left_black.svg" alt="Left Arrow" />
          </Arrow>
          <Arrow onClick={() => setCurrentImageIndex((prev) => (prev + 1) % images.length)}>
            <img src="./images/arrow_right_black.svg" alt="Right Arrow" />
          </Arrow>
        </ImageContainer>
      </TopSection>
      <HorizontalSection src="/images/horizontal_section.svg" alt="Divider" />
      <BottomSection>
        <div>
          <SPLText>
            SPL
            <SPLImage src="/images/star.svg" alt="SPL Icon 1" />
            <SPLImage src="/images/ellipse.svg" alt="SPL Icon 2" />
            <SPLImage src="/images/polygon.svg" alt="SPL Icon 3" />
          </SPLText>
          <TokenText>TOKEN</TokenText>
        </div>
        <RightTextContainer>
          <RightTopText>{'The token standard\nfor Solana network tokens'}</RightTopText>
          <RightBottomText>
            {
              'As an SPL token built on the Solana blockchain, $PAZ leverages the high throughput and low transaction fees offered by Solana\'s cutting-edge technology to enable fast and stable transactions within the Paradium ecosystem. By utilizing the Solana blockchain, $PAZ delivers a seamless and cost-effective user experience, facilitating the creation and transfer of digital assets with ease.'
            }
          </RightBottomText>
        </RightTextContainer>
      </BottomSection>
    </Container>
  );
}

export default AboutPage;
